<template>
  <usage-by-month
    endpoint="/api/admin/user-credits"
    prefix="Admin/Users"
  />
</template>

<script>
// Import the common usage table component
import UsageByMonth from "@/components/admin/common/UsageByMonth.vue"

// Export the SFC
export default {
  // Name of the component
  name: "AdminUserUsageByMonth",

  // Register the components
  components: {
    UsageByMonth
  }
}
</script>
